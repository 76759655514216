import { SignaturePadModule } from 'angular2-signaturepad';
import { PayslipFirmComponent } from './modals/payslip-firm/payslip-firm.component';
import { TermsSegurosComponent } from './modals/terms-seguros/terms-seguros.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommonMoneyInputComponent } from './common-money-input/common-money-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NotificationsHomeModalComponent } from './modals/notifications-home-modal/notifications-home-modal.component';
import { TermsModalComponent } from './modals/terms-modal/terms-modal.component';
import { NoticePrivacyComponent } from './modals/notice-privacy/notice-privacy.component';
import { TermsInformationModalComponent } from './modals/terms-information-modal/terms-information-modal.component';
import { TermsFormatModalComponent } from './modals/terms-format-modal/terms-format-modal.component';
import { SurveyNotAvailableComponent } from '../components/modals/survey-not-available/survey-not-available.component';

const components = [
  CommonMoneyInputComponent,
  DatepickerComponent,
  NotificationsHomeModalComponent,
  TermsModalComponent,
  NoticePrivacyComponent,
  TermsInformationModalComponent,
  TermsFormatModalComponent,
  TermsSegurosComponent,
  PayslipFirmComponent,
  SurveyNotAvailableComponent
];

const modalComponents = [
  NotificationsHomeModalComponent,
  TermsModalComponent,
  NoticePrivacyComponent,
  TermsInformationModalComponent,
  TermsFormatModalComponent,
  SurveyNotAvailableComponent,
  TermsSegurosComponent,
  PayslipFirmComponent
]

@NgModule({
  entryComponents: modalComponents,
  declarations: components,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    SignaturePadModule,
  ],
  exports: components,
  providers: [
    InAppBrowser
  ]
})
export class CommonComponentsModule {
}

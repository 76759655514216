import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-terms-information-modal',
  templateUrl: './terms-information-modal.component.html',
  styleUrls: ['./terms-information-modal.component.scss'],
})
export class TermsInformationModalComponent implements OnInit {

  user: any;

  constructor(public modalController : ModalController, private router: Router, private authService:AuthService) { }

  ngOnInit() {
    this.authService.user().subscribe(
      (data:any)=>{
        console.log("data", data);
        this.user = data;
      }
    );
  }

  closeModal(){
    this.modalController.dismiss();
  }

}

import { NgModule, LOCALE_ID, ErrorHandler  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { FileTransfer, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx'
import { HttpConfigInterceptor } from './interceptors/http-config.interceptor';
import { registerLocaleData } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ImportsPageModule } from 'src/app/imports/imports.module';
import { CommonComponentsModule } from 'src/app/components/common-components.module';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Smartlook } from '@ionic-native/smartlook/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';

registerLocaleData(localeEsMx);
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    ImportsPageModule,
    CommonComponentsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: LOCALE_ID, useValue: "es-MX" }, //replace "de-at" with your locale
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    NativeStorage,
    BarcodeScanner,
    SocialSharing,
    FileTransfer,
    File,
    FileOpener,
    Screenshot,
    AndroidPermissions,
    InAppBrowser,
    Network,
    WebView,
    PreviewAnyFile,
    HTTP,
    Keyboard,
    OneSignal,
    AppVersion,
    Badge,
    CallNumber,
    FingerprintAIO,
    Smartlook,
    Device
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

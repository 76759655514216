import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-terms-format-modal',
  templateUrl: './terms-format-modal.component.html',
  styleUrls: ['./terms-format-modal.component.scss'],
  providers: [DatePipe]
})
export class TermsFormatModalComponent implements OnInit {

  today: Date = new Date();

  user: any;

  constructor(public modalController : ModalController, private router: Router, private authService:AuthService) { }

  ngOnInit() {
    this.authService.user().subscribe(
      (data:any)=>{
        //console.log("data", data);
        this.user = data;
      }
    );
  }

  closeModal(){
    this.modalController.dismiss();
  }

}
